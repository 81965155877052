import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Metadata } from '../../models/common/common'
import {
  AccountClient,
  AccountCompany,
  AccountWarehouses,
  FilterAccountType,
} from '../../models/Account'
import { Client, ClientInterface } from '../../models'

export interface ClientFilterArrayInterface {
  data: {
    entry: AccountClient[]
    metadata: Metadata
  }
}

export interface CompaniesFilterArrayInterface {
  data: {
    entry: AccountCompany[]
    metadata: Metadata
  }
}

export interface WarehousesFilterArrayInterface {
  data: {
    entry: AccountWarehouses[]
    metadata: Metadata
  }
}

export interface AccountState {
  clientList?: AccountClient[]
  companyList?: AccountCompany[]
  warehouseList?: AccountWarehouses[]
  choosingClient?: Client
  choosingCompany?: string
  choosingWarehouse?: string
  loadingClient?: boolean
  loadingCompany?: boolean
  loadingWarehouse?: boolean
  clientFirstLoad?: boolean
}

const initialState: AccountState = {
  loadingClient: false,
  loadingCompany: false,
  loadingWarehouse: false,
  clientFirstLoad: false,
}

const accountSlice = createSlice({
  reducers: {
    chooseClient(
      state,
      action: PayloadAction<{
        client?: string
        company?: string
        warehouse?: string
      }>
    ) {},
    chooseClientSuccess(
      state,
      action: PayloadAction<{
        client?: ClientInterface
        company?: string
        warehouse?: string
      }>
    ) {
      state.choosingClient =
        action.payload.client?.data?.entry || state.choosingClient
      state.choosingCompany = action.payload.company || state.choosingCompany
      state.choosingWarehouse =
        action.payload.warehouse || state.choosingWarehouse
    },
    clearClient(state) {
      state.choosingClient = undefined
      state.choosingCompany = undefined
      state.choosingWarehouse = undefined
    },
    getClients(state, action: PayloadAction<FilterAccountType>) {
      state.loadingClient = true
    },
    getClientsSuccess(state, action: PayloadAction<AccountClient[]>) {
      state.clientList = action.payload
      state.loadingClient = false
    },
    getClientsFail(state, action: PayloadAction<string>) {
      state.clientList = []
      state.loadingClient = false
    },
    getCompanies(state, action: PayloadAction<FilterAccountType>) {
      state.loadingCompany = true
    },
    getCompaniesSuccess(state, action: PayloadAction<AccountCompany[]>) {
      state.companyList = action.payload
      state.loadingCompany = false
    },
    getCompaniesFail(state, action: PayloadAction<string>) {
      state.clientList = []
      state.loadingCompany = false
    },
    getWarehouses(state, action: PayloadAction<FilterAccountType>) {
      state.loadingWarehouse = true
    },
    getWarehousesSuccess(state, action: PayloadAction<AccountWarehouses[]>) {
      state.warehouseList = action.payload
      state.loadingWarehouse = false
    },
    getWarehousesFail(state, action: PayloadAction<string>) {
      state.warehouseList = []
      state.loadingWarehouse = false
    },
    setClientFirstLoad(state, action: PayloadAction<boolean>) {
      state.clientFirstLoad = action.payload
    },
  },
  name: 'account',
  initialState,
})

export const selectValidAccount = (state: any) =>
  !!state.account.choosingClient &&
  !!state.account.choosingCompany &&
  !!state.account.choosingWarehouse

const accountReducer = accountSlice.reducer
export default accountReducer

export const {
  chooseClient,
  chooseClientSuccess,
  getClients,
  getClientsSuccess,
  getClientsFail,
  getCompanies,
  getCompaniesSuccess,
  getCompaniesFail,
  getWarehouses,
  getWarehousesSuccess,
  getWarehousesFail,
  clearClient,
  setClientFirstLoad,
} = accountSlice.actions
