import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import {
  FTPConfigResponse,
  FTPConfigArrayInterface
} from '../../models'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface ftpList {
  status: StatusAPI
  list: FTPConfigResponse[]
  loading: boolean
}

export interface ftpState {
  ftpList: ftpList
  error: string
}

const initialState: ftpState = {
  ftpList: {
    status: StatusAPI.initial,
    list: [],
    loading: false,
  },
  error: '',
}

const parametresSlice = createSlice({
  name: 'Parametres',
  initialState,
  reducers: {
    clearList(state) {
      state.ftpList = initialState.ftpList
    },
    getFPTConfigsRequest(state) {
      state.ftpList.status = StatusAPI.calling
      state.ftpList.loading = true
    },
    getFPTConfigsSuccess(state, action: PayloadAction<FTPConfigArrayInterface>) {
      state.ftpList.status = StatusAPI.success
      state.ftpList.list = action.payload.data.entry
      state.ftpList.loading = false
    },
    getFPTConfigsFailed(state, action: PayloadAction<any>) {
      state.ftpList.status = StatusAPI.failure
      state.error = action.payload
      state.ftpList.loading = false
    }
  },
})

const parametresReducer = parametresSlice.reducer;
export const {
  clearList,
  getFPTConfigsRequest,
  getFPTConfigsSuccess,
  getFPTConfigsFailed,
} = parametresSlice.actions
export default parametresReducer