import {
  FTPConfigArrayInterface,
  FTPConfigDefaultResponse,
  FTPConfigPayload,
  FTPConnectPayload
} from '../models'
import baseAxios from './clientAPI';

const parametresApi = {
  getFTPConfigurationAll(): Promise<FTPConfigArrayInterface> {
    const url = `/warehouse/ftp/all`;
    return baseAxios.get( url );
  },
  
  createFTPConfiguration( payload: FTPConfigPayload ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp`
    return baseAxios.post( url, payload )
  },
  
  updateFTPConfiguration( payload: FTPConfigPayload ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp/${ payload.id }`
    return baseAxios.put( url, payload )
  },
  
  deleteFTPConfiguration( configId: string ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp/${ configId }`
    return baseAxios.delete( url )
  },
  
  checkConnectionFTPConfiguration( payload: FTPConnectPayload ): Promise<FTPConfigDefaultResponse> {
    const url = `warehouse/ftp/check-connection`
    return baseAxios.post( url, payload )
  }
}

export default parametresApi
