import { RootPage } from './../enum/pageEnum'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Page } from '../enum/pageEnum'
import {
  changeModalName,
  ModalName,
} from '../features/stock/modal/customModalSlice'
import { changePage, changeRootPage } from '../redux/reducers/pageSlice'

export const useChangeStockHook = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    //for root page
    if (location.pathname.includes(RootPage.TABLEAU_DE_BORD)) {
      dispatch(changeRootPage(RootPage.TABLEAU_DE_BORD))
      dispatch(changePage(Page.TABLEAU_DE_BORD))
      dispatch(changeModalName(ModalName.OTHER))
    } else if (location.pathname.includes(RootPage.GESTION_DE_STOCK)) {
      dispatch(changeRootPage(RootPage.GESTION_DE_STOCK))
      // for modal in stock screen
      if (location.pathname.includes(ModalName.ATTENDU)) {
        dispatch(changeModalName(ModalName.ATTENDU))
        dispatch(changePage(Page.ATTENDUS))
      } else if (location.pathname.includes(ModalName.COMMANDE)) {
        dispatch(changeModalName(ModalName.COMMANDE))
        dispatch(changePage(Page.COMMANDES))
      } else if (location.pathname.includes(ModalName.HISTORY)) {
        dispatch(changeModalName(ModalName.HISTORY))
        dispatch(changePage(Page.HISTORY))
      } else if (location.pathname.includes(ModalName.MISSION)) {
        dispatch(changeModalName(ModalName.MISSION))
        dispatch(changePage(Page.MISSION))
      } else if (location.pathname.includes(ModalName.STOCK)) {
        dispatch(changeModalName(ModalName.STOCK))
        dispatch(changePage(Page.STOCK))
      }
      else {
        dispatch(changeModalName(ModalName.OTHER))
        dispatch(changePage(''))
      }
    } else if (location.pathname.includes(RootPage.FACTURATION)) {
      dispatch(changeRootPage(RootPage.FACTURATION))
      dispatch(changeModalName(ModalName.OTHER))
    } else if (location.pathname.includes(RootPage.INVENTAIRE)) {
      dispatch(changeRootPage(RootPage.INVENTAIRE))
      dispatch(changeModalName(ModalName.OTHER))
    } else if (location.pathname.includes(RootPage.CONFIGURATIONS)) {
      dispatch(changeRootPage(RootPage.CONFIGURATIONS))
      dispatch(changeModalName(ModalName.OTHER))

      if (location.pathname.includes(Page.REFERENCE)) {
        dispatch(changePage(Page.REFERENCE))
      }
      if (location.pathname.includes(Page.SOCIETE)) {
        dispatch(changePage(Page.SOCIETE))
      }
      if (location.pathname.includes(Page.CLIENT)) {
        dispatch(changePage(Page.CLIENT))
      }
      if (location.pathname.includes(Page.UTILISATEUR)) {
        dispatch(changePage(Page.UTILISATEUR))
      }
      if(location.pathname.includes(Page.TIER)){
        dispatch(changePage(Page.TIER))
      }
    } else if (location.pathname.includes(RootPage.PARAMETRES)) {
      dispatch(changeRootPage(RootPage.PARAMETRES))
      dispatch(changeModalName(ModalName.OTHER))
    } else {
      dispatch(changeModalName(ModalName.OTHER))
      dispatch(changeRootPage(RootPage.OTHER))
    }
  }, [dispatch, location.pathname])
  return {}
}
