import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Fournisseur, Transporteur } from "../../models";
import fournisseurApi, { FilterFournisseurParam } from "../../http/fournisserApi";
import { PagingEnum } from "../../enum";
import { RootState } from "../../app/store";
import transporteurApi from "../../http/transporteurApi";
import destinataireApi from "../../http/destinataireApi";
import { TierRoute } from "../../features/configurations/tier/enum/TierRoute";
import { t } from "i18next";
import { showErrorNotification, showSuccessNotification } from "../../utils/notification";
import { AxiosError } from "axios";
import { RejectedWithValueActionFromAsyncThunk } from "@reduxjs/toolkit/dist/matchers";


export type FilterTierParam = {
  offset: number,
  limit: number,
  search?: string,
  client_id?: string | null
}

type TierInitState = {

  query: FilterTierParam
  data: {
    fournisseur: {
      loading: boolean,
      items: Fournisseur[],
      total_records?: number,
      selectedIds: string[]
    },
    transporteur: {
      loading: boolean,
      items: Transporteur[],
      total_records?: number,
      selectedIds:  string[]
    },
    destinataire: {
      loading: boolean,
      items: Fournisseur[],
      total_records?: number,
      selectedIds:  string[]
    },
  }
}

const initialState: TierInitState = {
  query: {
    offset: PagingEnum.DEFAULT_PAGE_INDEX - 1,
    limit: PagingEnum.DEFAULT_PAGE_SIZE,
    search: '',
    client_id: null
  },
  data: {
    fournisseur: {
      loading: false,
      items: [],
      selectedIds: []

    },
    transporteur: {
      loading: false,
      items: [],
      selectedIds: []

    },
    destinataire: {
      loading: false,
      items: [],
      selectedIds: []

    }
  }

};

export const filterFournisseurAction = createAsyncThunk(
  '/tier/fournisseurs',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const params = state.tier.query;
    const response = (await fournisseurApi.filterFournisseur(params)).data;
    return response;
  }
)

export const filterTransporteurAction = createAsyncThunk(
  '/tier/transporteurs',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const params = state.tier.query;
    const response = (await transporteurApi.filterTransporteur(params)).data;
    return response;
  }
)

export const filterDestinataireAction = createAsyncThunk(
  '/tier/destinataires',
  async (_, { getState }) => {
    const state = getState() as RootState;
    const params = state.tier.query;
    const response = (await destinataireApi.filterDestinataire(params)).data;
    return response;
  }
)

export const deleteDestinataireAction = createAsyncThunk(
  '/tier/destinataires/delete',
  async (_, { getState, dispatch , rejectWithValue}) => {
    try {
      const state = getState() as RootState;
      const selectedIds = state.tier.data.destinataire.selectedIds;
      const deleteId = selectedIds.length > 0 ? selectedIds[0] : ''
      const response = (await destinataireApi.deleteDestinataire(deleteId)).data;
      showSuccessNotification(t('deletetionSuccess'))
      dispatch(filterDestinataireAction())
      return response;
    } catch (error) {
      return rejectWithValue(handleErrorMsg(error))
    }

  }
)

export const deleteTransporteurAction = createAsyncThunk(
  '/tier/transporteurs/delete',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const selectedIds = state.tier.data.transporteur.selectedIds;
      const deleteId = selectedIds.length > 0 ? selectedIds[0] : ''
      const response = (await transporteurApi.deleteTransporteur(deleteId)).data;
      showSuccessNotification(t('deletetionSuccess'))
      dispatch(filterTransporteurAction())
      return response;
    } catch (error) {
      return rejectWithValue(handleErrorMsg(error))
    }
  }
)

export const deleteFournisseursAction = createAsyncThunk(
  '/tier/fournisseurs/delete',
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const selectedIds = state.tier.data.fournisseur.selectedIds;
      const deleteId = selectedIds.length > 0 ? selectedIds[0] : ''
      const response = (await fournisseurApi.deleteFournisseur(deleteId)).data;
      showSuccessNotification(t('deletetionSuccess'))
      dispatch(filterFournisseurAction())
      return response;
    } catch (error) {
      return rejectWithValue(handleErrorMsg(error))
    }
  }
)

function handleErrorMsg(error: any){
  if(error instanceof(AxiosError)){
    const errorMsg = error?.response?.data?.error || '';
    if(errorMsg === "Precondition Failed"){
      return t("clientOnOperationError")
    }
  }
  return t("popupErrorGeneral")
}


const tierSlice = createSlice({
  name: 'tier',
  initialState,
  reducers: {
    setParamAction(state, action) {
      const query = state.query;
      state.query = {
        ...query,
        ...action.payload
      }
    },
    setPagingAction(state, action) {
      const query = state.query;
      state.query = {
        ...query,
        ...action.payload
      }
      filterFournisseurAction();
    },
    clearParamsAction(state) {
      state.query = {
        offset: PagingEnum.DEFAULT_PAGE_INDEX - 1,
        limit: PagingEnum.DEFAULT_PAGE_SIZE,
        search: '',
        client_id: null
      }

    },
    setSelectedIdsAction(state, action){
      const { route, ids } = action.payload;
      if (route === TierRoute.Destinataires) {
        state.data.destinataire.selectedIds = ids;
      }
      if (route === TierRoute.Transporteurs) {
        state.data.transporteur.selectedIds = ids;
      }
      if (route === TierRoute.Fournisseurs) {
        state.data.fournisseur.selectedIds = ids;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(filterFournisseurAction.pending, (state, action) => {
        state.data.fournisseur.loading = true;
      })
      .addCase(filterFournisseurAction.fulfilled, (state, action) => {
        state.data.fournisseur.loading = false;
        state.data.fournisseur.items = action.payload.entry;
        state.data.fournisseur.total_records = action.payload.total_records
      })
      .addCase(filterFournisseurAction.rejected, (state, action) => {
        state.data.fournisseur.loading = false;
      })

      .addCase(filterTransporteurAction.pending, (state, action) => {
        state.data.transporteur.loading = true;
      })
      .addCase(filterTransporteurAction.fulfilled, (state, action) => {
        state.data.transporteur.loading = false;
        state.data.transporteur.items = action.payload.entry;
        state.data.transporteur.total_records = action.payload.total_records
      })
      .addCase(filterTransporteurAction.rejected, (state, action) => {
        state.data.transporteur.loading = false;
      })

      .addCase(filterDestinataireAction.pending, (state, action) => {
        state.data.destinataire.loading = true;
      })
      .addCase(filterDestinataireAction.fulfilled, (state, action) => {
        state.data.destinataire.loading = false;
        state.data.destinataire.items = action.payload.entry;
        state.data.destinataire.total_records = action.payload.total_records
      })
      .addCase(filterDestinataireAction.rejected, (state, action) => {
        state.data.destinataire.loading = false;
      })


      .addCase(deleteDestinataireAction.pending, (state, action) => {
        state.data.destinataire.loading = true;
      })
      .addCase(deleteDestinataireAction.fulfilled, (state, action) => {
        state.data.destinataire.loading = false;
      })
      .addCase(deleteDestinataireAction.rejected, (state, action) => {
        state.data.destinataire.loading = false;
        showErrorNotification(action.payload as string)
      })

      .addCase(deleteFournisseursAction.pending, (state, action) => {
        state.data.fournisseur.loading = true;
      })
      .addCase(deleteFournisseursAction.fulfilled, (state, action) => {
        state.data.fournisseur.loading = false;
      })
      .addCase(deleteFournisseursAction.rejected, (state, action) => {
        state.data.fournisseur.loading = false;
        showErrorNotification(action.payload as string)
      })


      .addCase(deleteTransporteurAction.pending, (state, action) => {
        state.data.transporteur.loading = true;
      })
      .addCase(deleteTransporteurAction.fulfilled, (state, action) => {
        state.data.transporteur.loading = false;
      })
      .addCase(deleteTransporteurAction.rejected, (state, action) => {
        state.data.transporteur.loading = false;
        showErrorNotification(action.payload as string)
      })
  },
})

const tierReducer = tierSlice.reducer;
export const { setParamAction, setPagingAction, clearParamsAction, setSelectedIdsAction } = tierSlice.actions;
export default tierReducer;