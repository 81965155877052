import React from 'react'
import { useSelector } from 'react-redux'
import { selectValidAccount } from '../redux/reducers/accountSlice'

const useValidAccount = () => {
  const isValidAccount = useSelector(selectValidAccount)
  return isValidAccount
}

export default useValidAccount
