import { cloneDeep, forEach, has, set } from 'lodash'
import {
  AppPermission,
  AppPermissionAction,
  PermissionAction,
  PermissionList,
} from '../models/Permission'
import { initPermissionList } from '../redux/reducers/permissionSlice'
import { store } from '../app/store'
import { UserRole } from '../enum/UserRole'
import { INIT_APP_PERMISSIONS } from '../features/configurations/utilisateur/userDetail/utils/constants'

export const isSuperAdmin = () => {
  const { permission } = store.getState()
  return permission.role === UserRole.SUPERADMIN
}

export const mapPermissionList = (
  permissions: (PermissionAction | AppPermissionAction)[] | undefined,
  isApp?: boolean
) => {
  const permissionList = cloneDeep(
    isApp ? INIT_APP_PERMISSIONS : initPermissionList
  ) as any
  // Reset all permissions to false
  resetPermissionList(permissionList)
  // Set permissions of the user to true
  if (permissions) {
    forEach(permissions, ({ action, entity }) => {
      if (has(permissionList, [entity, action])) {
        set(permissionList, [entity, action], true)
      }
    })
  }
  return permissionList
}

export const resetPermissionList = (
  permissionList: PermissionList | AppPermission
) => {
  forEach(permissionList, (permissions) => {
    forEach(permissions, (value, key) => {
      set(permissions, key, false)
    })
  })
}

export const isPermissionEnabled = () => true
