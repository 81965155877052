import {
  ReferenceArrayInterface,
  ReferenceResponse,
} from './../models/Reference'
import Define from '../constants/define'
import {
  Conditionnement,
  RefCharement,
  RefFournisser,
  RefLivraison,
  RefReception,
  ReferenceRequest,
  ExistedRefReference,
} from '../models'
import baseAxios from './clientAPI'
import { getUrlFilterParams } from '../utils'
import { GetAllPayload } from '../redux/reducers/attenduSlice'

export interface SearchResponse {
  code: number
  data: Data
  error: any
  status: string
}

export interface Data {
  entry: Entry[]
}

export interface Entry {
  reference_fournisser: string
  ref_nom: string
  id?: string
  blocage_entree?: boolean
  att_enable?: boolean
}

export interface FindByNumResponse {
  code: number
  data: DataFind
  error: any
  status: string
}

export interface DataFind {
  entry: EntryFind
}

export interface EntryFind {
  id: string
  create_at: number
  update_at: number
  nom: string
  libelle_long: string
  dluo: boolean
  dluo_value: number
  conditionnement: Conditionnement[]
  ref_fournissers: RefFournisser[]
  ref_charement: RefCharement
  ref_livraison: RefLivraison
  ref_reception: RefReception
  client_id: string
  gestion?: boolean
  blocage_entree?: boolean
}

interface DataDelete {
  message_error?: string
  status?: boolean
}
export interface DeleteOrUpdateResponse {
  code: number
  data: DataDelete
  error: any
  status: string
}

export interface UpdateReferenceRequest {
  conditionnement_delete: {
    id: string[]
  }
  ref_four_delete: {
    id: string[]
  }
  entry: ReferenceRequest
}
export interface AllowRefDeleteOrNot {
  code: number
  data: {
    status: boolean
  }
  error: string
  status: number
}
const referenceApi = {
  getReference(id: string): Promise<ReferenceResponse> {
    const url = `/warehouse/reference/${id}`
    return baseAxios.get(url)
  },
  getAllReference(payload: {}): Promise<ReferenceArrayInterface> {
    const url = '/warehouse/reference'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    return baseAxios.get(url, {
      params: {
        user_id: localStorage.getItem(Define.USER_ID),
        role: localStorage.getItem(Define.ROLE),
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize || 0,
        ...condition,
        supplier_label: undefined,
      },
    })
  },

  createReference(data: ReferenceRequest): Promise<ReferenceRequest> {
    const url = '/warehouse/reference'
    return baseAxios.post(url, data)
  },

  searchReferenceWithFournisser(
    nom: string,
    fournisser_id?: string,
    client_id?: string
  ): Promise<SearchResponse> {
    let url = `/warehouse/reference/find-by-nom-digit`
    const data = {
      client_id:
        client_id || localStorage.getItem(Define.CHOOSING_CLIENT) || '',
      nom: nom,
      fournisser_id,
    }
    return baseAxios.post(url, data)
  },

  searchRefFournisseurWithFournisser(
    reference_fournisser: string,
    fournisser_id: string,
    client?: string
  ): Promise<SearchResponse> {
    let url = `/warehouse/reference/find-by-ref-four-digit`
    const data = {
      client_id: client || localStorage.getItem(Define.CHOOSING_CLIENT) || '',
      reference_fournisser: reference_fournisser,
      fournisser_id,
    }
    return baseAxios.post(url, data)
  },

  findByNum(nom: string, client?: string): Promise<FindByNumResponse> {
    let url = '/warehouse/reference/find-by-num-for-att'
    const data = {
      client_id: client || localStorage.getItem(Define.CHOOSING_CLIENT) || '',
      nom,
    }
    return baseAxios.post(url, data)
  },

  checkFournisseurExisted(payload: {
    client_id: string
    fournisser_id: string
    reference_fournisser: string
  }): Promise<ExistedRefReference> {
    let url = '/warehouse/reference/exists-ref-four'
    return baseAxios.post(url, payload)
  },

  deleteById(id: string): Promise<DeleteOrUpdateResponse> {
    let url = `/warehouse/reference/${id}?modificationer_id=${
      localStorage.getItem(Define.USER_ID) || ''
    }`
    return baseAxios.delete(url)
  },

  updateReference(
    data: UpdateReferenceRequest
  ): Promise<DeleteOrUpdateResponse> {
    let url = '/warehouse/reference'
    return baseAxios.put(url, data)
  },

  checkRefAllowDeleteOrNot(id: string): Promise<AllowRefDeleteOrNot> {
    let url = `/warehouse/reference/valid-ref/${id}`
    return baseAxios.get(url)
  },

  checkRefFnsAllowDeleteOrNot(id: string): Promise<AllowRefDeleteOrNot> {
    let url = `/warehouse/reference/valid-ref-four/${id}`
    return baseAxios.get(url)
  },
  checkCdnAllowDeleteOrNot(id: string): Promise<AllowRefDeleteOrNot> {
    let url = `/warehouse/reference/valid-conditionnement/${id}`
    return baseAxios.get(url)
  },

  checkDuplicateEANVariante(payload: {
    ean: string
    variante: string
    id: string
  }): Promise<any> {
    let url = `/warehouse/reference/exists-ean-variante-for-conditionnement`
    return baseAxios.post(url, { payload })
  },
}

export default referenceApi
