import {
  getFPTConfigsRequest,
  getFPTConfigsSuccess,
  getFPTConfigsFailed,
  GetAllPayload
} from '../reducers/parametresSlice'
import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosError } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { take } from 'redux-saga/effects'
import parametresApi from '../../http/parametresApi'
import { FTPConfigArrayInterface } from '../../models'

function* fetchFTPConfigs(payload: GetAllPayload) {
  try {
    const item: FTPConfigArrayInterface = yield call(parametresApi.getFTPConfigurationAll)
    yield put(getFPTConfigsSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getFPTConfigsFailed(error.response?.data.error))
    } else {
      yield put(getFPTConfigsFailed('Something went wrong'))
    }
  }
}

function* watchFTPFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getFPTConfigsRequest.type)
    if (type) {
      yield fork(fetchFTPConfigs, type.payload)
    }
  }
}

export default function* parametresSaga() {
  yield all([fork(watchFTPFlow)])
}
